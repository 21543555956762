
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "8acd4237-5771-4009-8cb7-02c4b192b7c6"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/bogutskyy/belarus-border-web/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
